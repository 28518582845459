import { Box, Grid, Stack } from "@mui/material";
import { Typography, styled } from "@mui/material";
import Link from "@mui/material/Link";
import { lazy } from "react";
import { SvgIcon } from "../../common/SvgIcon";
import { NavLink } from "../../components/Layout/styles";
const Container = lazy(() => import("../../common/Container"));

const BlackTypography = styled(Typography)({
  color: "#000",
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "#0c35ff",
});

const CryptoPayment = () => {
  return (
    <Container>
      <Box paddingRight={12}>
        <BlackTypography variant="h3" align="left">
          Account Id
        </BlackTypography>
        <BlackTypography variant="body1" paddingTop={5}>
- Account Id allows you to buy VIP and other features on the official servers through crypto. You can find out more about VIP subscription at: <StyledLink
          href="https://kontramobile.com/#/subscriptions"
        >kontramobile.com/#/subscriptions</StyledLink>
        </BlackTypography>
        <BlackTypography variant="body1">
- Account Id is synced when you join official server. NOTE: Account Id will only be synced if you added a password in your profile.
        </BlackTypography>
        <BlackTypography variant="h3" align="left" paddingTop={5}>
          How to make a payment with crypto?
        </BlackTypography>
        <BlackTypography variant="body1" paddingTop={5}>
- You MUST send crypto with message. Message must only contain your account id (example: 8475837209304857)
        </BlackTypography>
        <BlackTypography variant="body1">
- Currently supported cryptos: ETH, MATIC & ADA
        </BlackTypography>
        <BlackTypography variant="body1">
          - Known ADA wallets that support messages:<StyledLink
          href="https://Eternl.io"
        > Eternl.io</StyledLink><StyledLink
          href="https://namiwallet.io/"
        > Nami</StyledLink>
        </BlackTypography>
        <BlackTypography variant="body1">
          - Known ETH, MATIC wallets that support messages: MyEtherWallet (ETH, MATIC messages must be converted to hex code, you can learn more about it
          <StyledLink
          href="https://dame.mirror.xyz/5xbwbVzWcO8vQkEIGlMaUjXNM6UNM8labiN-5QkVCHM"
          display="inline"
        > here</StyledLink>)
        </BlackTypography>
        <BlackTypography variant="h3" align="left" paddingTop={5}>
          Account balance
        </BlackTypography>
        <BlackTypography variant="body1" paddingTop={5}>
- You can check your account balance by typing in chat ‘/balance’
        </BlackTypography>
        <BlackTypography variant="body1">
- You can buy VIP by typing in chat ‘/buy_vip’
        </BlackTypography>
        <BlackTypography variant="h3" align="left" paddingTop={5}>
          Crypto Wallets
        </BlackTypography>
        <BlackTypography variant="body1" paddingTop={5}>
<img src="/img/icons/cardano.png" width={20}/> ADA: <a>addr1q8afu4jjjf4u75a23v8tqgjharhte06uekv9eks6cq4lmhkutqcz2x5merdxv6wtz6w0wqjcj2xr2py4td0akqnzen7s27vs0z</a>
        </BlackTypography>
        <BlackTypography variant="body1" paddingTop={2}>
<img src="/img/icons/ethereum.png" width={18}/> ETH: <a>0x20181376BcE7830D9449f1392405962E47BBACEf</a>
        </BlackTypography>
        <BlackTypography variant="body1" paddingTop={2} paddingBottom={5}>
<img src="/img/icons/polygon.png" width={20}/> MATIC (Polygon Network): <a>0x20181376BcE7830D9449f1392405962E47BBACEf</a>
        </BlackTypography>
      </Box>
    </Container>
  );
};

export default CryptoPayment;