import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import { Stack } from "@mui/material";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      {/* <FooterSection>
        <Container>
          <Row>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:gameplier.team@gmail.com">
                <Chat>{t(`Send Email`)}</Chat>
              </a>
            </Col>
          </Row>
        </Container>
      </FooterSection> */}
      <Extra>
        <Container border={true}>
          <Stack direction="row" spacing={2} paddingTop="2rem" justifyContent="flex-end">
              <SocialLink
                href="https://www.facebook.com/Gameplier"
                src="facebook.png"
              />
              <SocialLink
                href="https://discord.gg/HwVzkMWrxH"
                src="discord.png"
                
              />
              <SocialLink
                href="https://play.google.com/store/apps/details?id=com.gameplier.kontra"
                src="google_play.png"
              />
              <SocialLink
                href="https://apps.apple.com/us/app/kontra-multiplayer-fps/id1660215198"
                src="app_store.png"
              />
              {/* <SocialLink
                href="https://www.linkedin.com/in/lasha-kakabadze/"
                src="linkedin.svg"
              />
              <SocialLink
                href="https://medium.com/@lashakakabadze/"
                src="medium.svg"
              /> */}
          </Stack>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
