import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainLayout = styled("div")`
  background: rgb(241, 242, 243);
  display: grid;
  grid-template-rows: 55px calc(100vh - 55px);
  grid-template-columns: 150px auto;
  grid-template-areas:
    "navbar navbar"
    "sidebar content"
    "sidebar content";
`;

export const SideBar = styled("div")`
  background: inherit;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
  grid-area: sidebar;
  padding: 0 20px;
`;

export const Navbar = styled("div")`
  background: inherit;
  grid-area: navbar;
  background: #ddd;
`;

export const MainContent = styled("div")`
  background: inherit;
  grid-area: content;
`;

export const Title = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #18216d;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const NavLink = styled(Link)`
  display: inline;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  padding: 0.3rem 0.2rem;

  &:hover {
    background: #ddd;
  }
  &:hover,
  &:active,
  &:focus {
    color: #15418e;
  }
`;
