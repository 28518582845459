import CryptoPayment from "../pages/CryptoPayment";
import Home from "../pages/Home";
import Hosting from "../pages/HostingServer";
import Subscriptions from "../pages/Subscriptions";

const routes = [
  {
    path: ["/"],
    name: "Home",
    exact: true,
    component: Home,
  },
  {
    path: ["/HostingServer"],
    name: "HostingServer",
    exact: true,
    component: Hosting,
  },
  {
    path: ["/Subscriptions"],
    name: "Subscriptions",
    exact: true,
    component: Subscriptions,
  },
  {
    path: ["/CryptoPayment"],
    name: "Crypto Payment",
    exact: true,
    component: CryptoPayment,
  },
];

export default routes;
