import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import Layout from "../components/Layout";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Layout>
        <Switch>
          {routes.map((routeItem) => {
            return (
              <Route
                key={routeItem.name}
                path={routeItem.path}
                exact={routeItem.exact}
                component={routeItem.component}
              />
            );
          })}
        </Switch>
      </Layout>
      <Footer />
    </Suspense>
  );
};

export default Router;
