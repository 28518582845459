import { Box, Grid, Stack } from "@mui/material";
import { Typography, styled } from "@mui/material";
import Link from "@mui/material/Link";
import { lazy } from "react";
import { SvgIcon } from "../../common/SvgIcon";
import { NavLink } from "../../components/Layout/styles";
const Container = lazy(() => import("../../common/Container"));

const BlackTypography = styled(Typography)({
  color: "#000",
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "#0c35ff",
});

const Subscriptions = () => {
  return (
    <Container>
      <h6>
        In-game subscriptions
      </h6>
      <Box paddingRight={12} paddingTop={3}>
        <BlackTypography variant="h5" paddingTop={0}>
          &#x2022; VIP - $2.99/month
        </BlackTypography>
        <BlackTypography variant="body1" paddingTop={2}>
- VIP tag
        </BlackTypography>
        <BlackTypography variant="body1">
- Messages are visible in events feed
        </BlackTypography>
        <BlackTypography variant="body1">- Vote for kick (3 VIP votes needed)</BlackTypography>
        <BlackTypography variant="body1">
- Double jump
        </BlackTypography>
<BlackTypography variant="body1">- Starting balance: $5k</BlackTypography>
        <BlackTypography variant="body1">
- Money multiplier: 1.25x
                          </BlackTypography>
                          <BlackTypography variant="body1">
- VIP Zombie
                          </BlackTypography>
                          <BlackTypography variant="body1">
- VIP Knife in deathrun
        </BlackTypography>
        {/* <BlackTypography variant="body1" display="inline">
You can learn how to buy VIP here:
        </BlackTypography>
        <NavLink
          to="/CryptoPayment"
        > Crypto Payment</NavLink>
        <BlackTypography variant="h3" align="left" paddingTop={5}>
          Account balance
        </BlackTypography>
        <BlackTypography variant="body1" paddingTop={5}>
- You can check your account balance by typing in chat ‘/balance’
        </BlackTypography>
        <BlackTypography variant="body1">
- You can buy VIP by typing in chat ‘/buy_vip’
        </BlackTypography>
        <BlackTypography variant="h3" align="left" paddingTop={5}>
          Crypto Wallets
        </BlackTypography>
        <BlackTypography variant="body1" paddingTop={5}>
<img src="/img/icons/cardano.png" width={20}/> ADA: <a>addr1q8afu4jjjf4u75a23v8tqgjharhte06uekv9eks6cq4lmhkutqcz2x5merdxv6wtz6w0wqjcj2xr2py4td0akqnzen7s27vs0z</a>
        </BlackTypography>
        <BlackTypography variant="body1" paddingTop={2}>
<img src="/img/icons/ethereum.png" width={18}/> ETH: <a>0x20181376BcE7830D9449f1392405962E47BBACEf</a>
        </BlackTypography>
        <BlackTypography variant="body1" paddingTop={2} paddingBottom={5}>
<img src="/img/icons/polygon.png" width={20}/> MATIC (Polygon Network): <a>0x20181376BcE7830D9449f1392405962E47BBACEf</a>
        </BlackTypography> */}
        </Box>
      
    </Container>
  );
};

export default Subscriptions;