import { Box } from "@mui/material";
import { Typography, styled } from "@mui/material";
import Link from "@mui/material/Link";
import { lazy } from "react";
const Container = lazy(() => import("../../common/Container"));

const BlackTypography = styled(Typography)({
  color: "#000",
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "#0c35ff",
});

const Hosting = () => {
  return (
    <Container>
      <h6>Hosting public game on dedicated server</h6>
      <Box paddingRight={12} paddingTop={3}>
        <BlackTypography paddingBottom={5} fontSize={16}>Servers were updated 05.07.2024 7:27pm EET time</BlackTypography>
        <BlackTypography>The proper way is to host a game on a dedicated server.</BlackTypography>
        <BlackTypography>Recommended VM on AWS is t3a.micro ($7/m, the cheapest & can handle up to 16 players)</BlackTypography>
        <BlackTypography>1. In this example, we will host Linux dedicated server on AWS. You
          can use any other service you want.</BlackTypography>
          <BlackTypography>2. First, create account on <StyledLink
          href="https://aws.amazon.com/"
        >AWS</StyledLink>.</BlackTypography>
        <BlackTypography>3. Download latest KontraServer build for Linux/Mac/Windows from here:</BlackTypography>
        <BlackTypography variant="body1" padding={0}>
          
        </BlackTypography>
        <BlackTypography variant="h6" paddingTop={3} paddingBottom={1}>
        LINUX
        </BlackTypography>
        <StyledLink
          href="https://kontra-server-builds.s3.amazonaws.com/Deathmatch+%26+Arms+Race/KontraServer.zip"
          display="block"
        >
          Deathmatch & Arms Race
        </StyledLink>
        <StyledLink
          href="https://kontra-server-builds.s3.amazonaws.com/Deathrun/KontraServer.zip"
          display="block"
        >
          Deathrun
        </StyledLink>
        <StyledLink
          href="https://kontra-server-builds.s3.amazonaws.com/Surf/KontraServer.zip"
          display="block"
        >
          Surf
        </StyledLink>
        <StyledLink
          href="https://kontra-server-builds.s3.amazonaws.com/Zombie/KontraServer.zip"
          display="block"
        >
          Zombie
        </StyledLink>
        <BlackTypography variant="h6" paddingTop={3} paddingBottom={1}>
          WINDOWS
        </BlackTypography>
        <StyledLink
          href="https://kontra-server-builds.s3.amazonaws.com/Windows/Deathmatch+%26+Arms+Race/KontraServer.zip"
          display="block"
        >
          Deathmatch & Arms Race
        </StyledLink>
        <StyledLink
          href="https://kontra-server-builds.s3.amazonaws.com/Windows/Deathrun/KontraServer.zip"
          display="block"
        >
          Deathrun
        </StyledLink>
        <StyledLink
          href="https://kontra-server-builds.s3.amazonaws.com/Windows/Surf/KontraServer.zip"
          display="block"
        >
          Surf
        </StyledLink>
        <StyledLink
          href="https://kontra-server-builds.s3.amazonaws.com/Windows/Zombie/KontraServer.zip"
          display="block"
          paddingBottom={3}
        >
          Zombie
        </StyledLink>
        <BlackTypography variant="body1" display="inline">
          4. Launch Linux virtual machine,{" "}
        </BlackTypography>
        <StyledLink href="https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/EC2_GetStarted.html">
          tutorial
        </StyledLink>
        <BlackTypography variant="body1" padding={0}>
          5. Your virtual machine must be launched before proceeding further
        </BlackTypography>
        <BlackTypography variant="body1" padding={0}>
          6. Make sure you have your key pair, if not, read tutorial (4) again
        </BlackTypography>
        <BlackTypography variant="body1" paddingBottom={2}>
          7. Click on instance Id
        </BlackTypography>
      </Box>
      <Box
        component={"img"}
        src="/img/svg/image0.png"
        sx={{
          maxWidth: "100%",
        }}
      />
      <BlackTypography variant="body1" paddingTop={2} paddingBottom={2}>
        8. Click on security.
      </BlackTypography>
      <Box
        component={"img"}
        src="/img/svg/image1.png"
        sx={{
          maxWidth: "100%",
        }}
      />
      <BlackTypography variant="body1" paddingTop={2} paddingBottom={2}>
        9. Click on security groups.
      </BlackTypography>

      <Box
        component={"img"}
        src="/img/svg/image2.png"
        sx={{
          maxWidth: "100%",
        }}
      />
      <BlackTypography
        variant="body1"
        paddingRight={12}
        paddingTop={2}
        paddingBottom={2}
      >
        10. Click on edit inbound rules.
      </BlackTypography>
      <Box
        component={"img"}
        src="/img/svg/image3.png"
        sx={{
          maxWidth: "100%",
        }}
      />
      <BlackTypography
        variant="body1"
        paddingRight={12}
        paddingTop={2}
        paddingBottom={2}
      >
        11. Click on add rule twice, modify them as shown on screenshot below,
        we set port 26951 (default server port), you can change it to whatever
        value/range you want, click save rules.
      </BlackTypography>

      <Box
        component={"img"}
        src="/img/svg/image4.png"
        sx={{
          maxWidth: "100%",
        }}
      />
      <BlackTypography
        variant="body1"
        paddingRight={12}
        paddingTop={2}
        paddingBottom={2}
      >
        12. If you haven’t done already, add a third rule for SSH, set source to
        your public ip or Anywhere (0.0.0.0/0), as shown on screenshot
      </BlackTypography>

      <Box
        component={"img"}
        src="/img/svg/image5.png"
        sx={{
          maxWidth: "100%",
        }}
      />
      <BlackTypography
        variant="body1"
        paddingRight={12}
        paddingTop={2}
        paddingBottom={2}
      >
        13. Click on outbound rules, click on edit outbound rules and add rules
        as shown on screenshot, save rules
      </BlackTypography>

      <Box
        component={"img"}
        src="/img/svg/image6.png"
        sx={{
          maxWidth: "100%",
        }}
      />
      <Box paddingRight={12} paddingTop={2}>
        <BlackTypography variant="body1">{`14. Next sections 14-16 are also explained on AWS: search ec2, click on instances -> instance Id -> Connect -> SSH Client`}</BlackTypography>
        <BlackTypography variant="body1">{`15. Launch terminal (Command Prompt on windows) at your key pair location`}</BlackTypography>
        <BlackTypography variant="body1">{`16. (not required) Run this command: chmod 400 <your key> (not required, won't work on windows)`}</BlackTypography>
        <BlackTypography variant="body1">{`17. Connect to instance: ssh -i “<your key>” ec2-user@<instance public ip>`}</BlackTypography>
        <BlackTypography variant="body1">{`18. Launch new terminal (Command Prompt on windows)`}</BlackTypography>
        <BlackTypography variant="body1">{`19. Upload build to your instance via command: scp -i “<your key>” -r <server build path (WINDOWS EXAMPLE: C:/Users/User/Downloads/KontraServer.zip)> ec2-user@<instance public ip>:/home/ec2-user/`}</BlackTypography>
        <BlackTypography variant="body1">{`20. When upload finishes, you can close the terminal`}</BlackTypography>
        <BlackTypography variant="body1">{`21. Return to your previous terminal and type: ls (you should see KontraServer folder), sudo yum install unzip, unzip KontraServer, cd KontraServer ; type ls again to see the content of the folder`}</BlackTypography>
        <BlackTypography variant="body1">{`22. Type screen`}</BlackTypography>
        <BlackTypography variant="body1">{`23. Launch server via command: ./KontraServer.x86_64`}</BlackTypography>
        <BlackTypography variant="body1">{`24. You can now close server terminal, type Cntrl+a d then close terminal`}</BlackTypography>
        <BlackTypography variant="body1">{`25. If server launches successfully, you should now be able to see it in browser menu, try to connect to the server via public ip & port (default port is 26951), click on Find By Ip in browser menu (Play->Browse Games->Find By Ip)`}</BlackTypography>
        <BlackTypography variant="body1">{`26. If you can’t connect via public ip, then you probably did not configure server correctly`}</BlackTypography>
        <BlackTypography variant="body1">{`27. If you want to reopen server terminal, check section 17 & then type screen -r`}</BlackTypography>
        <BlackTypography variant="body1">{`28. If you want to close server terminal, type Cntrl+a d then close terminal`}</BlackTypography>
        <BlackTypography variant="body1">{`29. To terminate virtual machine, click on instance Id->instance state->terminate instance`}</BlackTypography>
      </Box>
      <BlackTypography variant="h4" paddingTop={8}>
        Hosting LAN game on your own computer
      </BlackTypography>
      <Box paddingRight={12} paddingTop={3}>
        <BlackTypography variant="body1">{`1. You can either create LAN server in game, by clicking Play->Offline/LAN (worse performance), or you can download latest KontraServer build for Linux/Mac/Windows from links shared above.`}</BlackTypography>
        <BlackTypography variant="body1">{`2. Unzip, go inside KontraServer folder, launch the server (Linux: KontraServer.x86_64, windows: KontraServer.exe)`}</BlackTypography>
        <BlackTypography variant="body1">{`3. If your computer is connected to the same network, you should now see the server in LAN browser (Play->Browse Games)`}</BlackTypography>
      </Box>
      <BlackTypography variant="h4" paddingTop={8}>
        Hosting public game on your own computer (NOT RECOMMENDED)
      </BlackTypography>

      <Box paddingRight={12} paddingTop={3}>
        <BlackTypography variant="body1">{`1. Enable port forwarding (you can learn more about port forwarding on the internet)`}</BlackTypography>
        <BlackTypography variant="body1">{`2. Download latest KontraServer build for Linux/Mac/Windows using links shared above.`}</BlackTypography>
        <BlackTypography variant="body1">{`3. Unzip, go inside KontraServer folder, launch the server (Linux: KontraServer.x86_64, windows: KontraServer.exe). By default server is launched on port 26951`}</BlackTypography>
        <BlackTypography variant="body1">{`4. You should now be able to connect to the server via public ip & port, find out your public ip of the computer by searching on the internet “my public ip”, click on Find By Ip in browser menu (Play->Browse Games->Find By Ip)`}</BlackTypography>
        <BlackTypography variant="body1">{`5. If you can’t connect via public ip, then you probably did not configure port forwarding correctly`}</BlackTypography>
      </Box>
      <BlackTypography variant="h4" paddingTop={8}>
        Server cmd line arguments
      </BlackTypography>
      <Box paddingRight={12} paddingTop={3}>
        <BlackTypography variant="body1">{`* -private (do not show server in browser menu)`}</BlackTypography>
        <BlackTypography variant="body1">{`* -port <value> (assign port, by default 26951 ; example: -port 26955)`}</BlackTypography>
        <BlackTypography variant="body1">{`* -gamemode <gamemode> (assign gamemode, by default deathmatch ; possible values: deathmatch, armsrace, deathrun, surf, zombie)`}</BlackTypography>
        <BlackTypography variant="body1">{`* -start_map <map> (assign starting map)`}</BlackTypography>
        <BlackTypography variant="body1">{`* -title <value> (shortcut for assigning server title, you can alternatively assign it in server.json)`}</BlackTypography>
        <BlackTypography variant="body1">{`* -adminkey <password> (assign global admin, can be used to further configure admins/configs via in-game commands w/out using .json files)`}</BlackTypography>
        <BlackTypography variant="body1">{`* -spectatorkey <password>`}</BlackTypography>
        <BlackTypography variant="body1">{`* -max_players <value>`}</BlackTypography>
        <BlackTypography
          variant="body1"
          paddingTop={2}
        >{`linux example: ./KontraServer.x86_64 -port 26955 -gamemode deathmatch 
`}</BlackTypography>
      </Box>
      <BlackTypography variant="h4" paddingTop={8}>
        Admin, VIP, Bans & Configs
      </BlackTypography>
      <Box paddingRight={12} paddingTop={3}>
        <BlackTypography variant="body1">{`- Configuration files can be found in KontraServer folder (admin.json, bans.json, configs.json)`}</BlackTypography>
        <BlackTypography variant="body1">{`- Check out example files that explain everything (admin_example.json, bans_example.json, configs_example.json)`}</BlackTypography>
        <BlackTypography variant="body1">{`- You can also use in-game commands to add/remove admins, bans & change configs`}</BlackTypography>
        <BlackTypography
          variant="body1"
          paddingBottom={5}
        >{`- You can avoid using admin.json file by configuring global admin via cmd line: -adminkey (check Server cmd line arguments section)`}</BlackTypography>
      </Box>
    </Container>
  );
};

export default Hosting;
