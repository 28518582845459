import React from "react";
import { MainLayout, SideBar, MainContent, Navbar, NavLink } from "./styles";

interface SidebarProps {
  children?: any;
}

const Sidebar = ({ children }: SidebarProps) => {
  console.log(children);
  return (
    <MainLayout>
      {/* <Navbar /> */}
      <SideBar>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/HostingServer">Hosting</NavLink>
        <NavLink to="/Subscriptions">Subscriptions</NavLink>
        {/* <NavLink to="/CryptoPayment">Crypto Payment</NavLink> */}
      </SideBar>
      <MainContent>{children}</MainContent>
    </MainLayout>
  );
};

export default Sidebar;
